import ReactDOM from 'react-dom/client'
import App from './App'
import { ApolloWrapper } from './apollo-client'

import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // https://github.com/ant-design/ant-design/issues/38794
  <ApolloWrapper>
    <App />
  </ApolloWrapper>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
