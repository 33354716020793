import { useQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import { graphql } from '../gql'

const GET_CAMPAIGN = graphql(`
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      url
    }
  }
`)

export const Detail = () => {
  const { id } = useParams()
  const [params] = useSearchParams()

  const { data, loading, error } = useQuery(GET_CAMPAIGN, {
    variables: { id: id! },
  })

  if (loading) return <>loading...</>
  if (error) return <>error...</>

  if (data?.getCampaign?.url) {
    if (!params.get('debug')) {
      window.location.href = data?.getCampaign?.url
    }
    return <>redirecting {data?.getCampaign?.url}</>
  }

  return <>no campaign found for ID '{id}'</>
}
