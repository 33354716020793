import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Detail } from './pages/Detail'
import { Result } from 'antd'

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Result
          status={404}
          title="Page not found"
          subTitle="Page You are looking for seems to not exist"
        />
      ),
    },
    {
      path: '/:id',
      element: <Detail />,
    },
  ])

  return (
    <>
      <RouterProvider router={router} fallbackElement={<>loading...</>} />
    </>
  )
}

export default App
